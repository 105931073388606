<template>
  <div>
    <div v-if="showDomainSearch" class="domain-choice-container">
      <domain-search
        class="section-margin"
        :bus="bus"
        @domain-unavailable="domainUnavailable = true"
        @domain-available="domainUnavailable = false"
      />
      <div>
        <div v-if="domainUnavailable" class="section-margin">
          <span class="inform-text">
            {{ text.unavailableDomain }}
          </span>
          <div class="unavailable-name-display-container">
            <span class="searched-result">
              {{ domainSearch }}
            </span>
            <div class="false-button-container">
              <span class="false-button">
                {{ text.fakeButton }}
              </span>
            </div>
          </div>
        </div>
        <transfer-service-section
          v-if="!domainSearchAvailable && !domainAdminSwap"
          :service-type="'domain'"
          :domain-unavailable="domainUnavailable"
          @show-transfer-modal="showTransferModal()"
        />
        <standalone-hosting-section
          v-if="unredeemedHostingOrEmailVoucher && !domainAdminSwap"
          @show-standalone-hosting-modal="showHostingModal()"
        />
      </div>
      <div
        v-if="domainOptionsExist"
        class="section-margin"
      >
        <domain-options :bus="bus" />
      </div>
    </div>
    <standalone-hosting-section
      v-if="!showDomainSearch && unredeemedHostingOrEmailVoucher && !domainAdminSwap"
      :show-or="false"
      @show-standalone-hosting-modal="showHostingModal()"
    />
    <domain-transfer-status v-if="activeDomainTransferRequest" />
    <domain-transfer-modal v-model="showDomainTransferModal" :bus="bus" />
    <standalone-hosting-modal v-model="showStandaloneHostingModal" :bus="bus" @next-slide="$emit('next-slide')" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ChooseDomain',
  components: {
    DomainTransferModal:     () => import('@/components/Services/Domains/DomainTransferModal'),
    StandaloneHostingModal:  () => import('@/components/Services/Hosting/StandaloneHostingModal.vue'),
    DomainTransferStatus:    () => import('@/components/Services/Domains/DomainTransferStatus'),
    DomainOptions:           () => import('@/components/Services/Domains/DomainOptions'),
    DomainSearch:            () => import('@/components/Services/Domains/DomainSearch'),
    TransferServiceSection:  () => import('@/components/Services/shared/TransferServiceSection.vue'),
    StandaloneHostingSection:() => import('@/components/Services/shared/StandaloneHostingSection.vue'),
  },
  props: {
    showNameChange: {
      type: Boolean,
      default: true,
    },
    domainAdminSwap: {
      type: Boolean,
      default: false,
    },
    domain: {
      type: Object,
      default: null,
    },
    bus: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      text: {
        availableDomain: 'Your domain is available',
        unavailableDomain: 'Domain Is Unavailable',
        fakeButton: 'Unavailable',
      },
      domainUnavailable: false,
      showDomainTransferModal: false,
      showStandaloneHostingModal: false,
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'company',
    ]),
    ...mapGetters('vouchers', [
      'unRedeemedVoucherByProductCategoryCompanyId',
    ]),
    ...mapGetters('domains', [
      'activeDomainTransferRequest',
      'domainSearchAvailable',
      'domainSearchResults',
      'domainSearch',
    ]),
    companyId() {
      return this.company?.id || this.domain?.company_id
    },
    showDomainSearch() {
      const modalsAreOpen = this.showDomainTransferModal
      return this.domainAdminSwap || (!modalsAreOpen && !this.activeDomainTransferRequest && this.hasUnRedeemedDomainVoucher)
    },
    unredeemedHostingOrEmailVoucher() {
      return !!this.unRedeemedVoucherByProductCategoryCompanyId('business-email', this.companyId) ||
        !!this.unRedeemedVoucherByProductCategoryCompanyId('business-website-hosting', this.companyId)
    },
    hasUnRedeemedDomainVoucher() {
      return !!this.unRedeemedVoucherByProductCategoryCompanyId('business-domain', this.companyId)
    },
    domainOptionsExist() {
      return this.domainSearchResults?.length > 0
    },
  },
  watch: {
    domainOptionsExist(newValue) {
      if (newValue) {
        this.bus.$emit('scroll-to-bottom')
      }
    },
  },
  async mounted() {
    await this.fetchCompanyVouchers(this.companyId)
  },
  methods: {
    ...mapActions('vouchers', [
      'fetchCompanyVouchers',
    ]),
    async showTransferModal() {
      await this.bus.$emit('navigate', 'domain-transfer-modal')
      await this.bus.$emit('log-domain-interaction', { name: 'domain-transfer-click' })

      this.showDomainTransferModal = true
    },
    async showHostingModal() {
      await this.bus.$emit('navigate', 'standalone-hosting-modal')

      this.showStandaloneHostingModal = true
    },
  },
}
</script>

<style lang="scss" scoped>
// Palette
$gray2-light3: #E9E9E9;
$gray3-light2: #F8F8F8;
$black-light3: #C8C7C7;
$disabled-color: #5A5757;
$disabled-color2: #76787A;

.domain-choice-container {
  display: flex;
  flex-direction: column;

  .section-margin {
    margin-top: 3em;
  }
  .inform-text {
    font-weight: $ct-ui-font-weight-5;
    font-size: 1.5em;
    margin-bottom: 1em;
  }
  .unavailable-name-display-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background-color: $gray3-light2;
    padding: 1em;
    margin: 1em 0;
    border-bottom: 1px solid $black-light3;

    span {
      align-self: center;
      color: $disabled-color;
      font-size: 1.2em;
      font-weight: $ct-ui-font-weight-5;
    }
    .false-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 8.125em;
      height: 2.813em;
      background-color: $gray2-light3;
      font-weight: $ct-ui-font-weight-7;
      font-style: italic;
      font-size: 1em;
      color: $disabled-color;
      border: none;
      border-radius: 4px;
    }
  }
}

@media only screen and (max-width: 375px) {
  .domain-choice-container {
    .unavailable-name-display-container{
      flex-direction: column;

      .searched-result {
        width: 100%;
        justify-content: flex-start;
      }
      .false-button-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 1em;

        .false-button {
          width: 100%;
        }
      }
    }
  }
}
</style>
